import React from 'react'
import { Box } from '@chakra-ui/react'
import { BackToTop } from 'composable/components/backtotop'

const BackToTopTastic: React.FC = () => {
  return (
    <Box height="auto !important" width={12}>
      <BackToTop></BackToTop>
    </Box>
  )
}

export default BackToTopTastic
